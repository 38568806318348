const getLocationHash = () => {
  return location.hash
}

const accordionButtons = document.querySelectorAll('.cmp-accordion__button')
accordionButtons.forEach(button => {
  button.addEventListener('click', e => {
    event.preventDefault()
    const nearestButton = e.target.closest('button')
    const clickedID = nearestButton.id
    window.location.hash = clickedID

    const locationHash = getLocationHash()
    const headerHeight = document.querySelector('.header')?.clientHeight || 0
    setTimeout(() => {
      const elementPosition = document.querySelector(locationHash).getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerHeight
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }, 0)
  })
})
